import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";

import { OverviewMapPage } from "./OverviewMapPage";
import { PrivacyPage } from "./PrivacyPage";
import { Header } from "./components/Header";
import { AlertPopup } from "./components/AlertPopup";
import { OfferMap } from "./components/OfferMap";
import AuthCallback from "./AuthCallback";
import ErrorPage from "./components/ErrorPage";
import { TenantMapProvider, useTenantMap } from "./components/TenantMapContext";
import SuccessPurchase from "./components/SuccessPurchase";
import PurchaseError from "./components/PurchaseError";
import MyMapsList from "./components/MyMapsList";
import { useUser } from "./components/UserContext";
import { TermsAndConditionsPage } from "./TermsAndConditionsPage";
import usePageTracking from "./usePageTracking";
import Tiers from "./components/Tiers";
import LoadingScreen from "./components/LoadingScreen";
import { useAlert } from "./components/AlertContext";
import {
  useApiService,
  CreateCheckoutSessionResponse,
} from "@visitswitzerland/common";

const DynamicOfferRoute: React.FC<{
  setTitle: (title: string, subtitle: string) => void;
}> = ({ setTitle }) => {
  const { tenantConfig, isLoading } = useTenantMap();

  useEffect(() => {
    // don't show title and subtitle in header as it is already shown in the Tiers component
    setTitle("", "");
  }, [setTitle]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!tenantConfig) {
    return <div>Error: Map configuration not found</div>;
  }

  return (
    <div style={{ paddingTop: "66px", paddingBottom: "10px" }}>
      <OfferMap
        title={tenantConfig.title}
        creator={tenantConfig.creator}
        tenantName={tenantConfig.tenant}
        mapName={tenantConfig.map}
        description={tenantConfig.description}
        imagePath={tenantConfig.imagePath}
      />
    </div>
  );
};

const DynamicMapRoute: React.FC<{
  setTitle: (title: string, subtitle: string) => void;
}> = ({ setTitle }) => {
  const { tenantConfig, isLoading } = useTenantMap();
  const { isAuthenticated } = useAuth0(); // Get authentication status

  useEffect(() => {
    if (tenantConfig) {
      setTitle(tenantConfig.title, tenantConfig.creator);
    }
  }, [tenantConfig, setTitle]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!tenantConfig) {
    return <div>Error: Map configuration not found</div>;
  }

  // Redirect to offer page if not authenticated
  if (!isAuthenticated) {
    return (
      <Redirect to={`/${tenantConfig.tenant}/${tenantConfig.map}/offer`} />
    );
  }

  return (
    <OverviewMapPage
      mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      gygPartnerID={process.env.REACT_APP_GYG_PARTNER_ID}
    />
  );
};

const DynamicTierRoute: React.FC<{
  setTitle: (title: string, subtitle: string) => void;
}> = ({ setTitle }) => {
  const { tenantConfig, isLoading } = useTenantMap();

  // don't show title and subtitle in header as it is already shown in the Tiers component
  setTitle("", "");

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!tenantConfig) {
    return <div>Error: Map configuration not found</div>;
  }

  return (
    <div style={{ paddingTop: "66px", paddingBottom: "60px" }}>
      <Tiers
        title={tenantConfig.creator}
        subtitle={tenantConfig.title}
        description={tenantConfig.description}
        profileImage={tenantConfig.profileImagePath}
        tiers={tenantConfig.tiers || []}
      />
    </div>
  );
};

const PurchaseRoute: React.FC<{
  setTitle: (title: string, subtitle: string) => void;
}> = ({ setTitle }) => {
  const { tenantConfig, isLoading } = useTenantMap();
  const { isAuthenticated, user } = useAuth0();
  const { showAlert } = useAlert();
  const apiService = useApiService();
  const searchParams = new URLSearchParams(window.location.search);
  const priceID = searchParams.get("price_id");

  useEffect(() => {
    if (isAuthenticated && tenantConfig && user && priceID) {
      apiService
        .createCheckoutSession(
          priceID,
          tenantConfig.tenant,
          tenantConfig.map,
          user.email,
          user.name
        )
        .then((response: CreateCheckoutSessionResponse) => {
          const checkoutUrl = response.url;
          if (checkoutUrl) {
            window.location.href = checkoutUrl;
          } else {
            console.error("Checkout URL not found in the response.");
            showAlert("Oops! Something went wrong.", "error", 5);
          }
        })
        .catch((error) => {
          console.error("Error creating checkout session:", error);
          showAlert("Oops! Something went wrong.", "error", 5);
        });
    }
  }, [isAuthenticated, tenantConfig, user, priceID, apiService, showAlert]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!tenantConfig) {
    return <div>Error: Map configuration not found</div>;
  }

  if (!isAuthenticated) {
    return (
      <Redirect to={`/${tenantConfig.tenant}/${tenantConfig.map}/tiers`} />
    );
  }

  return <LoadingScreen />;
};

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null; // Render nothing as we're performing a full page reload
};

interface AppStateProps {
  history: History;
}

const App: React.FC<AppStateProps> = ({ history }) => {
  const { isLoading } = useAuth0();
  const { profile } = useUser();
  const [title, setTitle] = useState<string | null>(null);
  const [subtitle, setSubtitle] = useState<string | null>(null);

  const handleSetTitle = (title: string, subtitle: string) => {
    setTitle(title);
    setSubtitle(subtitle);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ConnectedRouter history={history}>
      <PageTracker />
      <Helmet>{/* Dynamic Helmet setup can be done here if needed */}</Helmet>
      <Header
        logoClickURL={window.location.origin + window.location.pathname}
        contactFormURL={process.env.REACT_APP_CONTACT_FORM}
        title={title}
        subtitle={subtitle}
      />
      <div
        style={{
          position: "absolute",
          top: 70,
          left: 0,
          right: 0,
          zIndex: 1600,
        }}
      >
        <AlertPopup />
      </div>
      <Switch>
        <Route exact path="/">
          <ExternalRedirect to={process.env.REACT_APP_HOME_REDIRECT_URL} />
        </Route>
        <Route path="/auth-callback" component={AuthCallback} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/terms" component={TermsAndConditionsPage} />
        <Route
          path="/checkout/success/:tenant_name/:map_name/:user_id"
          render={(props) => (
            <SuccessPurchase
              {...props}
              style={{ paddingTop: "96px", marginTop: "66px" }}
            />
          )}
        />
        <Route
          path="/checkout/cancel/:tenant_name/:map_name/:user_id"
          render={(props) => (
            <PurchaseError
              {...props}
              style={{ paddingTop: "96px", marginTop: "10px" }}
            />
          )}
        />
        <Route
          path="/mymaps"
          render={() => (
            <MyMapsList
              configs={profile?.mymaps || []}
              setTitle={handleSetTitle}
              style={{ paddingTop: "66px", marginTop: "10px" }}
            />
          )}
        />
        <Route path="/:tenant/:map/tiers">
          <TenantMapProvider>
            <DynamicTierRoute setTitle={handleSetTitle} />
          </TenantMapProvider>
        </Route>
        <Route path="/:tenant/:map/offer">
          <TenantMapProvider>
            <DynamicOfferRoute setTitle={handleSetTitle} />
          </TenantMapProvider>
        </Route>
        <Route path="/:tenant/:map/purchase">
          <TenantMapProvider>
            <PurchaseRoute setTitle={handleSetTitle} />
          </TenantMapProvider>
        </Route>
        <Route path="/:tenant/:map">
          <TenantMapProvider>
            <DynamicMapRoute setTitle={handleSetTitle} />
          </TenantMapProvider>
        </Route>
        <Route
          path="*"
          render={() => (
            <div style={{ paddingTop: "66px", paddingBottom: "10px" }}>
              <ErrorPage />
            </div>
          )}
        />
      </Switch>
    </ConnectedRouter>
  );
};

// PageTracker is a helper component to add the usePageTracking hook
// Because the usePageTracking hook needs to be called somehere within the ConnectedRouter component
const PageTracker = () => {
  usePageTracking();
  return null;
};

// Connect the App component to Redux store if needed
export default connect()(App);
