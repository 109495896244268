import React, { useState } from "react";
import {
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import { sendEvent } from "../analytics/events";
import history from "../redux/history";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAction from "./useAuthAction";

export interface OfferMapProps {
  title: string;
  creator: string;
  tenantName: string;
  mapName: string;
  description: string;
  imagePath: string;
}

export function OfferMap({
  title,
  creator,
  tenantName,
  mapName,
  description,
  imagePath,
}: OfferMapProps) {
  const { isAuthenticated, user } = useAuth0();
  const authAction = useAuthAction();

  const handleTryForFree = () => {
    sendEvent("User", "Click Try for Free Offer", `${tenantName} - ${mapName}`);

    if (!isAuthenticated) {
      authAction({
        action: "signup",
        tenantName,
        mapName,
        redirectAfterLogin: `/${tenantName}/${mapName}`,
      });
    } else {
      history.push(`/${tenantName}/${mapName}`);
    }
  };

  return (
    <Card sx={{ maxWidth: 345, mx: "auto" }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="subtitle2"
          component="div"
          color="text.secondary"
          textAlign="center"
        >
          {creator}
        </Typography>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          textAlign="center"
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" textAlign="center">
          {description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          size="large"
          variant="contained"
          fullWidth
          sx={{
            bgcolor: "#404bb9",
            mb: 1,
            textTransform: "none",
            "&:hover": {
              bgcolor: "#404bb9",
            },
          }}
          onClick={handleTryForFree}
        >
          Try for Free
        </Button>
      </CardActions>
      <CardMedia
        component="img"
        sx={{
          width: 200,
          height: "auto",
          objectFit: "contain",
          mx: "auto",
          mb: 2,
        }} // Added bottom margin (mb)
        image={imagePath}
        alt={title}
      />
    </Card>
  );
}
