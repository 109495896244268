import { TenantConfig } from "@visitswitzerland/common"; // Import TenantConfig type

export const getMockMapData = () => {
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [10.058166, 46.326302],
        },
        properties: {
          platform: "youtube",
          id: "ZrfnTlXSalA",
          categories: ["Hotels"],
          timeStart: 0,
          icon: "Accommodation",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [6.747698, 46.491604],
        },
        properties: {
          platform: "youtube",
          categories: ["Autumn", "Lakes"],
          timeStart: 674,
          icon: "Photospots",
          locked: true,
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [8.514431, 47.399525],
        },
        properties: {
          platform: "youtube",
          id: "TGu68T-iHY8",
          categories: ["Sports", "Cities", "Summer"],
          timeStart: 53,
          icon: "Cities & Villages",
          locked: true,
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [7.755215, 46.003153],
        },
        properties: {
          platform: "instagram",
          id: "C2XBaVEqd-h",
          categories: ["Four-thousander", "Multi-Day Hike", "Autumn"],
          icon: "Trails & Hiking",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [8.805913, 46.734802],
        },
        properties: {
          platform: "instagram",
          categories: ["Autumn", "White-Blue-White", "Hike"],
          icon: "Trails & Hiking",
          locked: true,
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [7.460054, 46.948677],
        },
        properties: {
          platform: "instagram",
          categories: ["Cities", "Summer", "Rivers"],
          icon: "Cities & Villages",
          locked: true,
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [9.020191, 46.192997],
        },
        properties: {
          platform: "tiktok",
          categories: ["Summer"],
          icon: "Culture & Tradition",
          locked: true,
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [9.342285, 47.24913],
        },
        properties: {
          platform: "tiktok",
          categories: ["Winter"],
          icon: "Photospots",
          locked: true,
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [7.579075, 47.551414],
        },
        properties: {
          platform: "instagram",
          categories: ["Spring"],
          icon: "Cities & Villages",
          locked: true,
        },
      },
    ],
  };
};

export const getMockMapMetaInfo = (
  tenantName: string,
  mapName: string
): TenantConfig[string][string] | null => {
  const tenantMapConfigs: TenantConfig = {
    djemographic: {
      topvillages: {
        tenant: "djemographic",
        map: "topvillages",
        creator: "@djemographic",
        profileImagePath:
          process.env.REACT_APP_STATIC_RESOURCES +
          "/djemographic_profile_176x176.jpg",
        title: "Top Switzerland Guide",
        description:
          "Switzerland's Highlights and Secrets Unveiled: This interactive map features my YouTube videos and Instagram spots, guiding you to less-known, non-touristy places around popular destinations. Unveil the real beauty of Switzerland and explore like a local!",
        imagePath:
          process.env.REACT_APP_STATIC_RESOURCES +
          "/offer_map_djemographic_top_villages_300x593.gif",
        tiers: [
          {
            name: "Full Map",
            price: "CHF 19.90",
            priceID: process.env.REACT_APP_DJEMOGRAPHIC_TOPVILLAGES_PRICE_ID,
            features: ["> 200 spots", "Exclusive Filters"],
            buttonText: "Buy Now",
            buttonColor: "secondary",
            recommended: true,
          },
          {
            name: "Free",
            price: "CHF 0",
            features: ["Limited selection of spots"],
            buttonText: "Try It Out",
            buttonColor: "primary",
          },
        ],
      },
    },
    robi_crusoe: {
      demo: {
        tenant: "robi_crusoe",
        map: "demo",
        creator: "@robi_crusoe",
        profileImagePath:
          process.env.REACT_APP_STATIC_RESOURCES +
          "/robi_crusoe_profile_256x256.png",
        title: "Badass Demo Map",
        description:
          "This map shows how badass Pioneer Maps can be. It's a demo map, so don't expect too much. But it's still pretty cool.",
        imagePath:
          process.env.REACT_APP_STATIC_RESOURCES +
          "/offer_map_robi_crusoe_demo_map_300x593.gif",
        tiers: [
          {
            name: "Full Map",
            price: "$ 19.90",
            priceID:
              process.env.REACT_APP_ROBI_CRUSOE_VISIT_SWITZERLAND_MAP_PRICE_ID,
            features: ["100+ spots", "Popular spots", "Basic Filters"],
            buttonText: "Buy Now",
            buttonColor: "secondary",
            recommended: true,
          },
          {
            name: "Free",
            price: "$0",
            features: ["Limited selection of spots"],
            buttonText: "Explore",
            buttonColor: "primary",
          },
        ],
      },
    },
    michelphotographych: {
      "secret-gems-of-switzerland": {
        tenant: "michelphotographych",
        map: "secret-gems-of-switzerland",
        creator: "@michelphotographych",
        profileImagePath:
          process.env.REACT_APP_STATIC_RESOURCES +
          "/michelphotographych_profile_210x210.png",
        title: "Secret Gems of Switzerland",
        description:
          "My favorite spots in Switzerland - in one map. This unique map gives you access to many of my favourite places in Switzerland. It is constantly being expanded and you will receive lots of valuable information about the individual spots.",
        imagePath:
          process.env.REACT_APP_STATIC_RESOURCES +
          "/offer_map_michelphotographych_secret-gems-of-switzerland_300x593.gif",
        tiers: [
          {
            name: "Full Map",
            price: "$19.90",
            priceID:
              process.env
                .REACT_APP_MICHELPHOTOGRAPHYCH_SECRET_GEMS_OF_SWITZERLAND_PRICE_ID,
            features: ["100+ spots", "Popular spots", "Exclusive Filters"],
            buttonText: "Buy Now",
            buttonColor: "secondary",
            recommended: true,
          },
          {
            name: "Free",
            price: "$0",
            features: ["Limited selection of spots"],
            buttonText: "Explore",
            buttonColor: "primary",
          },
        ],
      },
    },
  };

  return tenantMapConfigs[tenantName]?.[mapName] || null;
};
