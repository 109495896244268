import axios, { AxiosRequestConfig } from "axios";
import {
  IGetPhotoResponse,
  IGetVideoResponse,
  ITag,
  LinkTreeResponse,
  CreateCheckoutSessionResponse,
  GetMapsResponse,
} from "./types";
import { getMockMapData, getMockMapMetaInfo } from "./mockApi";

type TokenRetrievalFunction = () => Promise<string | null>;

class API {
  private getToken: TokenRetrievalFunction;

  constructor(getToken: TokenRetrievalFunction) {
    this.getToken = getToken;
  }

  public async getPhoto(
    platformName: string,
    id: string,
    tenant?: string,
    map?: string
  ): Promise<IGetPhotoResponse> {
    const params: any = {
      platform: platformName,
      id: id,
      ...(tenant && { tenant }),
      ...(map && { map }),
    };

    const { data } = await axios.get<IGetPhotoResponse>("/photo", {
      params,
    });

    return data;
  }

  public async getVideo(
    platformName: string,
    id: string,
    tenant?: string,
    map?: string
  ): Promise<IGetVideoResponse> {
    const params: any = {
      platform: platformName,
      id,
      ...(tenant && { tenant }),
      ...(map && { map }),
    };

    // Destructure the `data` from the axios response and return it
    const { data } = await axios.get<IGetVideoResponse>("/video", {
      params,
    });

    return data;
  }

  public async getMap(
    tenantName: string,
    mapName: string,
    authenticated = false
  ): Promise<any> {
    const useMockApi = process.env.REACT_APP_USE_MOCK_MAP_API === "true";
    if (useMockApi) {
      return getMockMapData();
    } else {
      const url = `/map/${tenantName}/${mapName}`;

      // Get the headers for the request based on the authentication status
      const headers = authenticated ? await this.getAuthHeaders() : {};
      const response = await axios.get(url, headers);

      return response.data;
    }
  }

  public async getMapMetaInfo(
    tenantName: string,
    mapName: string
  ): Promise<any> {
    const useMockApi = process.env.REACT_APP_USE_MOCK_MAP_META_API === "true";
    if (useMockApi) {
      return getMockMapMetaInfo(tenantName, mapName);
    } else {
      const url = `/map-meta/${tenantName}/${mapName}`;

      const response = await axios.get(url, {});

      return response.data;
    }
  }

  // Create Checkout Session with user email and username
  public async createCheckoutSession(
    priceId: string,
    tenantName: string,
    mapName: string,
    userEmail: string,
    userName?: string
  ): Promise<CreateCheckoutSessionResponse> {
    // Define the payload with required data
    const payload = {
      price_id: priceId,
      tenant_name: tenantName,
      map_name: mapName,
      user_email: userEmail,
    };

    // Add the user name to the payload if it is not empty and different from the user email
    if (userName !== undefined && userName !== "" && userName !== userEmail) {
      payload["user_name"] = userName;
    }

    // Make the API call to create a checkout session
    const { data } = await axios.post<CreateCheckoutSessionResponse>(
      "/create-checkout-session",
      payload,
      await this.getAuthHeaders()
    );

    return data;
  }

  public async getMaps(): Promise<GetMapsResponse> {
    const { data } = await axios.get<GetMapsResponse>("/maps", {
      ...(await this.getAuthHeaders()),
    });

    return data;
  }

  public async postTag(body: ITag): Promise<any> {
    return axios.post("/tag", body, {
      ...(await this.getAuthHeaders()),
    });
  }

  public async getLinkTree(
    username: string,
    limit: number,
    offset: number
  ): Promise<LinkTreeResponse> {
    const { data } = await axios.get<LinkTreeResponse>("/linktree", {
      params: {
        username,
        limit,
        offset,
      },
    });

    return data;
  }

  private async getAuthHeaders(): Promise<AxiosRequestConfig> {
    const token = await this.getToken();
    return token ? { headers: { Authorization: `Bearer ${token}` } } : {};
  }
}

export default API;
