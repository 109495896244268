import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { Tier } from "./Tiers";
import { useApiService } from "@visitswitzerland/common";

export interface TenantConfig {
  [key: string]: {
    [key: string]: MapConfigItem;
  };
}

export interface MapConfigItem {
  tenant: string;
  map: string;
  creator: string;
  profileImagePath: string;
  title: string;
  description: string;
  imagePath: string;
  tiers?: Tier[];
}

interface TenantMapContextValue {
  tenantConfig: TenantConfig[string][string] | undefined;
  isLoading: boolean;
  updateTenantMap: (config: TenantConfig[string][string]) => void;
}

export const TenantMapContext = createContext<TenantMapContextValue | null>(
  null
);

export const useTenantMap = () =>
  useContext(TenantMapContext) as TenantMapContextValue;

interface TenantMapProviderProps {
  children: ReactNode;
}

export const TenantMapProvider: React.FC<TenantMapProviderProps> = ({
  children,
}) => {
  const [currentConfig, setCurrentConfig] = useState<
    TenantConfig[string][string] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { tenant, map } = useParams<{ tenant: string; map: string }>();

  const apiService = useApiService();

  const updateTenantMap = useCallback(
    (config: TenantConfig[string][string]) => {
      setCurrentConfig(config);
      setIsLoading(false);
    },
    []
  );

  useEffect(() => {
    const fetchMapMetaInfo = async () => {
      if (tenant && map) {
        setIsLoading(true);
        const config = await apiService.getMapMetaInfo(tenant, map);
        updateTenantMap(config);
      }
    };

    if (tenant && map) {
      fetchMapMetaInfo();
    }
  }, [tenant, map, updateTenantMap, apiService]);

  return (
    <TenantMapContext.Provider
      value={{ tenantConfig: currentConfig, isLoading, updateTenantMap }}
    >
      {children}
    </TenantMapContext.Provider>
  );
};
