import React, { useEffect } from "react";
import history from "./redux/history";
import { useAuth0 } from "@auth0/auth0-react";

const AuthCallback: React.FC = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      let redirectPath = "/";

      if (isAuthenticated) {
        // Login scenario: Get the returnTo path from Auth0's appState
        getAccessTokenSilently().then(() => {
          redirectPath = localStorage.getItem("postLoginRedirectPath") || "/";
          localStorage.removeItem("postLoginRedirectPath"); // Clean up
          window.location.href = redirectPath;
        });
      } else {
        // Logout scenario: Get the redirect path from localStorage
        redirectPath = localStorage.getItem("postLogoutRedirectPath") || "/";
        localStorage.removeItem("postLogoutRedirectPath"); // Clean up
        window.location.href = redirectPath;
      }
    }
  }, [isAuthenticated, isLoading, history, getAccessTokenSilently]);

  return <div>Loading...</div>;
};

export default AuthCallback;
