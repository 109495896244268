export const PlatformIcons = new Map([
  ["tiktok", "TikTokIcon.svg"],
  ["youtube", "YouTubeIcon.svg"],
  ["vimeo", "VimeoIcon.svg"],
  ["instagram", "InstaIcon.svg"],
]);

export const SeasonIcons = new Map([
  ["Winter", "WinterIcon.svg"],
  ["Spring", "SpringIcon.svg"],
  ["Summer", "SummerIcon.svg"],
  ["Autumn", "AutumnIcon.svg"],
]);

export const TypeIcons = new Map([
  ["Trails & Hiking", "TrailsAndHiking.svg"],
  ["Nature & Outdoors", "NatureAndOutdoors.svg"],
  ["Cities & Villages", "CitiesAndVillages.svg"],
  ["Accommodation", "Accommodation.svg"],
  ["Trains", "Trains.svg"],
  ["Roads", "Roads.svg"],
  ["Photospots", "Photospots.svg"],
  ["Culture & Tradition", "CultureAndTradition.svg"],
  ["Adventure & Action", "AdventureAndAction.svg"],
  ["Food & Drinks", "FoodAndDrinks.svg"],
]);

export const LockedIcon = "LockedIcon.svg";
