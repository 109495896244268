import React, { useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Avatar,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import history from "../redux/history";
import { useAlert } from "./AlertContext";
import { useApiService } from "@visitswitzerland/common";
import useAuthAction from "./useAuthAction";
import { sendEvent } from "../analytics/events";

export interface Tier {
  name: string;
  price: string;
  priceID: string | null;
  features: string[];
  buttonText: string;
  buttonColor: "primary" | "secondary";
  recommended?: boolean;
  disclaimer?: string;
}

interface TiersProps {
  tiers: Tier[];
  title: string;
  subtitle: string;
  description: string;
  profileImage: string;
}

const Tiers: React.FC<TiersProps> = ({
  tiers,
  title,
  subtitle,
  description,
  profileImage,
}) => {
  const { tenant, map } = useParams<{ tenant: string; map: string }>();
  const { isAuthenticated, user } = useAuth0();
  const apiService = useApiService();
  const authAction = useAuthAction();

  const handleTryForFree = () => {
    sendEvent("User", "Click Try for Free Tiers", `${tenant} - ${map}`);
    if (!isAuthenticated) {
      authAction({
        action: "signup",
        tenantName: tenant,
        mapName: map,
        redirectAfterLogin: `/${tenant}/${map}`,
      });
    } else {
      history.push(`/${tenant}/${map}`);
    }
  };

  const handleCheckout = (priceID: string | null) => {
    sendEvent("User", "Click Buy Now Tiers", `${tenant} - ${map}`);
    if (!isAuthenticated) {
      authAction({
        action: "signup",
        redirectAfterLogin: `/${tenant}/${map}/purchase?price_id=${priceID}`,
      });
    } else if (priceID) {
      history.push(`/${tenant}/${map}/purchase?price_id=${priceID}`);
    }
  };

  return (
    <Container
      style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
    >
      <Avatar
        alt={title}
        src={profileImage}
        sx={{ width: 88, height: 88, margin: "0 auto 10px" }}
      />
      <Typography variant="h6" component="h2" gutterBottom textAlign="center">
        {title}
      </Typography>
      <Typography variant="h4" component="h1" gutterBottom textAlign="center">
        {subtitle}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        paragraph
        textAlign="center"
        style={{ maxWidth: "600px", margin: "0 auto 20px" }}
      >
        {description}
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {tiers.map((tier) => (
          <Grid item key={tier.name} xs={12} sm={6} md={3}>
            <Card
              raised={tier.recommended}
              style={
                tier.recommended
                  ? {
                      border: "2px solid #3f51b5",
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
                      backgroundColor: "#f0f4ff",
                    }
                  : {
                      backgroundColor: "#f9f9f9",
                    }
              }
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  {tier.name}
                </Typography>
                <Typography variant="h6" component="p" gutterBottom>
                  {tier.price}
                </Typography>
                <ul style={{ paddingLeft: "20px" }}>
                  {tier.features.map((feature, index) => (
                    <li key={index} style={{ marginBottom: "10px" }}>
                      {feature}
                    </li>
                  ))}
                </ul>
                {tier.disclaimer && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                    textAlign="center"
                    mt={2}
                  >
                    {tier.disclaimer}
                  </Typography>
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color={tier.buttonColor}
                  fullWidth
                  onClick={
                    tier.name === "Free"
                      ? handleTryForFree
                      : () => handleCheckout(tier.priceID)
                  }
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Tiers;
