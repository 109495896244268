import { useAuth0 } from "@auth0/auth0-react";
import { sendEvent } from "../analytics/events";

interface AuthActionOptions {
  action: "signup" | "login";
  tenantName?: string;
  mapName?: string;
  options?: any;
  redirectAfterLogin?: string;
}

export default function useAuthAction() {
  const { loginWithRedirect } = useAuth0();

  return async ({
    action,
    tenantName,
    mapName,
    options = {},
    redirectAfterLogin,
  }: AuthActionOptions) => {
    try {
      // Store the desired return path in localStorage
      localStorage.setItem(
        "postLoginRedirectPath",
        redirectAfterLogin || window.location.href
      );

      options.authorizationParams = options.authorizationParams || {};
      options.authorizationParams.tenantName = tenantName;
      options.authorizationParams.mapName = mapName;
      options.authorizationParams.env = process.env.REACT_APP_ENV;
      options.authorizationParams.fromPath = window.location.pathname;

      if (action === "signup") {
        options.authorizationParams.screen_hint = "signup";
        sendEvent("User", "Click Sign up", `${tenantName} - ${mapName}`);
      } else {
        sendEvent("User", "Click Login", `${tenantName} - ${mapName}`);
      }

      await loginWithRedirect(options);
    } catch (err) {
      sendEvent("Error", `${action} failure`, `${tenantName} - ${mapName}`);
      console.error(`Error during ${action}`, err);
    }
  };
}
