import React, { useEffect } from "react";
import FormLabel from "@mui/material/FormLabel";
import { Button } from "./Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { CategorySelect } from "@visitswitzerland/common/src";
import CheckIcon from "@mui/icons-material/Check";
import { sendEvent } from "../analytics/events";
import { useTenantMap } from "./TenantMapContext";
import history from "../redux/history";

export interface FilterFormProps {
  title: string;
  filterModalOpen: boolean;
  handleFilterModalClose: Function;
  onCloseButton: Function;
  selectedCategories: string[];
  setSelectedCategories: Function;
  categoryCounts: { [key: string]: number };
  locked?: boolean;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 286,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: 2, // This adds space between child elements
};

const CheckBoxStyle = {
  color: "#2A3235",
};

export const FilterForm: React.FC<FilterFormProps> = ({
  title,
  filterModalOpen,
  handleFilterModalClose,
  onCloseButton,
  selectedCategories,
  setSelectedCategories,
  categoryCounts,
  locked = false,
}) => {
  const handleChange = (event) => {
    const { name, checked } = event.target;

    // Trigger GA event
    const label = name;
    const action = checked ? "filter select" : "filter deselect";
    sendEvent("User", action, label);
  };

  const { tenantConfig } = useTenantMap();

  const handleSelectedCategoriesChange = (selectedCategories) => {
    // Trigger GA event for each selected or deselected category
    selectedCategories.forEach((category) => {
      if (!selectedCategories.includes(category)) {
        sendEvent("User", "filter deselect", category);
      } else {
        sendEvent("User", "filter select", category);
      }
    });

    setSelectedCategories(selectedCategories);
  };

  const handleCategoryClick = (category) => {
    if (locked) {
      const handleBuyNow = () => {
        sendEvent(
          "User",
          "Clicked Locked Category",
          `${tenantConfig.tenant} - ${tenantConfig.map}`
        );
        history.push(`/${tenantConfig.tenant}/${tenantConfig.map}/tiers`);
      };
      handleBuyNow();
    } else {
      handleSelectedCategoriesChange(category);
    }
  };

  return (
    <Modal
      open={filterModalOpen}
      /* @ts-ignore*/
      onClose={handleFilterModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormLabel
          component="legend"
          sx={{ textAlign: "center", width: "100%" }}
        >
          {title}
        </FormLabel>
        <CategorySelect
          name={"Filters"}
          categories={Object.entries(categoryCounts).map(
            ([category, count]) => ({ name: category, count })
          )}
          onSelectionChange={handleCategoryClick}
          selectedCategories={selectedCategories}
          locked={locked}
        />
        <Box sx={{ mt: 2, textAlign: "center" }}>
          {" "}
          {/* Adjusted marginTop */}
          <Button
            onClick={() => {
              onCloseButton();
            }}
            icon={<CheckIcon />}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
